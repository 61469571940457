@use "../assets/variables" as v;

.Projects {
  background-color: v.$bk-main;
  padding: 0 clamp(1.75rem, 8vw, 6rem) clamp(2rem, 10vh, 6rem)
    clamp(1.75rem, 10vw, 10rem);

  .section-title {
    font-size: v.$heading;
    color: v.$text-main;
    padding-bottom: 15px;
    .numbering {
      font-size: v.$heading;
      color: v.$theme-main;
    }
  }
  .section-title::after {
    content: "";
    display: inline-block;
    position: relative;
    top: -5px;
    width: clamp(100px, 20vw, 500px);
    height: 1px;
    margin-left: 20px;
    background-color: rgb(69, 75, 94);
  }

  .projects-container {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
}

.Project {
  width: clamp(100%, 80vw, 1000px);
  padding: 10px;
  height: min-content;

  color: v.$text-secondary;
  background-color: v.$bk-secondary;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

  display: flex;
  flex-direction: column;
  gap: 10px;

  .imageContainer {
    width: 100%;

    display: flex;
    justify-content: center;
    .gif {
      width: clamp(300px, 100%, 500px);
      height: clamp(225px, 90vh, 325px);

      object-fit: cover;
      margin: 0 auto;
      position: relative;
      background-size: cover;
    }
  }
  .project-title {
    text-align: center;
    color: v.$text-main;
    font-size: v.$sub-heading;
    align-self: center;
  }
  .subheading {
    text-align: center;
    color: v.$text-main;
    padding-bottom: 5px;
  }
  p {
    font-size: 19px;
  }

  .projectBulletPoints {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    li {
      width: clamp(290px, 80vw, 680px);
      padding: 0 5px 0 5px;
    }
    li::before {
      content: "\2022";
      padding-right: 5px;
      padding-left: 5px;
      color: v.$theme-main;
      font-size: 20px;
    }
  }
  .technologies-container {
    ul {
      list-style: none;

      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      li {
        font-size: 19px;
        padding: 0 5px 0 5px;

        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2px;
        .techImage {
          width: 40px;
          height: 40px;
        }
        .techName {
          font-size: 7px;
          text-align: center;
        }
      }
    }
  }

  .project-links {
    padding-top: 15px;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    a {
      text-align: center;
      width: 200px;
      border: solid 2px v.$theme-main;
      border-radius: 5px;
      color: v.$theme-main;
      padding: 10px 25px;
      cursor: pointer;
    }
    a:hover {
      background-color: rgb(92, 225, 230, 0.2);
    }
  }
}
