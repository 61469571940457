@use "../assets/variables" as v;

.Intro {
  min-height: 100vh;
  padding: 100px clamp(2.5rem, 8vw, 6rem) clamp(2rem, 10vh, 6rem)
    clamp(2.5rem, 10vw, 10rem);
  background-color: v.$bk-main;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.5px;

  .sub-intro {
    color: v.$theme-main;
  }
  h1 {
    color: v.$text-main;
    font-size: clamp(25px, 8vw, 65px);
  }
  .role,
  .description {
    color: rgb(130, 141, 175);
  }
  .description {
    font-size: 15px;
    padding-bottom: 15px;
    max-width: 550px;
  }

  button {
    max-width: 175px;
    border: solid 2px v.$theme-main;
    border-radius: 5px;
    color: v.$theme-main;
    padding: 1rem 3rem;
    background-color: transparent;
    cursor: pointer;
  }
  button:hover {
    background-color: rgb(92, 225, 230, 0.2);
  }

  // Animation properties for first page load
  .sub-intro,
  .name,
  .role,
  .description,
  button {
    position: relative;
    opacity: 0;
  }
  .sub-intro {
    animation: fadeIn 1s forwards, dropDown 1s forwards;
  }
  .name {
    animation: fadeIn 1s forwards;
    animation-delay: 1s;
  }

  .role {
    animation: fadeIn 1s forwards;
    animation-delay: 1.5s;
  }
  .description,
  button {
    animation: fadeIn 1s forwards;
    animation-delay: 2.5s;
  }
}
