@use "../assets/variables" as v;
@import "./navbar";
@import "./intro";
@import "./aboutme";
@import "./sidebars";
@import "./projects";
@import "./contact";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700&family=Roboto+Mono:wght@300;400;700&display=swap");

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-size: 16px;
}
a:visited,
a {
  color: inherit;
  text-decoration: none;
}

p,
h1,
h2,
h3,
h4 {
  cursor: default;
}
html {
  background-color: v.$bk-main;
}

// Reveals sections as you scroll into them as opposed to displaying them right away.
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1.5s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes dropDown {
  from {
    top: -100px;
  }
  to {
    top: 0px;
  }
}

@keyframes spring {
  0% {
    transform: scaleX(1);
  }
  80% {
    transform: scaleX(1.1);
  }
  100% {
    transform: scaleX(1);
  }
}
