@use "../assets/variables" as v;

.Contact {
  min-height: 100vh;
  padding: 0 clamp(2.5rem, 8vw, 6rem) 10px clamp(2.5rem, 10vw, 10rem);

  background-color: v.$bk-main;
  color: v.$text-main;

  display: flex;
  flex-direction: column;

  .section-title {
    flex: 1;
    font-size: v.$heading;
    color: v.$text-main;
    .numbering {
      font-size: v.$heading;
      color: v.$theme-main;
    }
  }
  .section-title::after {
    content: "";
    display: inline-block;
    position: relative;
    top: -5px;
    width: clamp(60px, 20vw, 500px);
    height: 1px;
    margin-left: 20px;
    background-color: rgb(69, 75, 94);
  }

  .contact-form {
    flex: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 18px;
    .formTitle {
      width: clamp(300px, 50vw, 800px);
      text-align: center;

      display: flex;
      justify-content: center;
      align-items: center;
      .icon {
        font-size: 50px;
      }
      p {
        padding-left: 5px;
        padding-right: 30px;
        font-size: 25px;
        font-weight: bold;
      }
    }
    .inputField {
      width: clamp(300px, 50vw, 800px);
      padding: 10px;

      border-radius: 5px;
      border-width: 0;
      resize: none;

      background-color: v.$bk-secondary;
      color: white;
    }
    .inputField::placeholder {
      color: v.$text-main;
    }
    .submit-btn {
      border: solid 2px v.$theme-main;
      border-radius: 5px;
      color: v.$theme-main;
      padding: 1rem 3rem;
      background-color: transparent;
      cursor: pointer;
    }
    .submit-btn:hover {
      background-color: rgb(92, 225, 230, 0.2);
    }
    .default-mailer {
      border: solid 2px v.$bk-main;
      border-radius: 5px;
      color: v.$text-main;
      background-color: transparent;
      padding: 2px 5px;
      cursor: pointer;
      a {
        font-size: 14px;
      }
    }
    .default-mailer:hover {
      background-color: rgb(92, 225, 230, 0.2);
    }
  }

  .contact-links {
    flex: 1;
    min-width: 320px;
    margin-top: auto;

    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    .icon {
      cursor: pointer;
      color: v.$theme-main;
      font-size: v.$heading;
      transition: transform 0.5s;
    }
    .icon:hover {
      cursor: pointer;
      transform: scale(1.2);
      color: v.$theme-main;
    }
  }
}
