@use "../assets/variables" as v;

.AboutMe {
  min-height: 100vh;
  background-color: v.$bk-main;
  padding: 0 clamp(2.5rem, 8vw, 6rem) clamp(2rem, 10vh, 6rem)
    clamp(2.5rem, 10vw, 10rem);

  display: flex;
  flex-direction: column;

  .section-title {
    font-size: v.$heading;
    padding-bottom: 15px;
    color: v.$text-main;
    .numbering {
      font-size: v.$heading;
      color: v.$theme-main;
    }
  }
  .section-title::after {
    content: "";
    display: inline-block;
    position: relative;
    top: -5px;
    width: clamp(60px, 20vw, 500px);
    height: 1px;
    margin-left: 20px;
    background-color: rgb(69, 75, 94);
  }

  .aboutme-container {
    flex: 1;

    display: flex;
    flex-direction: column;
    justify-content: center;
    .aboutme-main {
      padding-bottom: 25px;

      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 10px;
      .aboutme-picture {
        width: clamp(225px, 70vw, 275px);
        height: clamp(225px, 70vw, 275px);
        border-radius: 50%;
        border: solid white 1px;

        background-image: url("https://res.cloudinary.com/de2ymful4/image/upload/v1655165554/main-portfolio/me_no_bk_wpvono.png");
        background-position: center;
        background-size: cover;
      }
      .aboutme-information {
        padding-top: 5px;
        max-width: 500px;
        .info {
          color: v.$text-secondary;
          padding-bottom: 15px;
        }
      }
    }

    .skills-title-frontend {
      padding-bottom: 5px;
      color: v.$text-main;
      text-align: center;
    }
    .skills-title-backend {
      margin-top: 15px;
      padding-bottom: 5px;
      color: v.$text-main;
      text-align: center;
    }
    .skills-container {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 5px;
    }
  }
}

.SkillCard {
  width: 70px;
  height: 70px;

  padding: 5px;

  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 40px;
    height: 40px;
  }
  p {
    font-size: 14px;
    margin-top: auto;
    color: v.$text-secondary;
  }
}
