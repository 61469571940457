@use "../assets/variables" as v;

.Navbar {
  position: fixed;
  top: 0;
  z-index: 2;
  width: 100%;
  height: 100px;

  background-color: transparent;
  transition: top 0.3s;
  padding: 0 20px 0 20px;

  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 2.5s;

  display: flex;
  justify-content: space-between;
  align-items: center;
  img {
    width: 50px;
    height: 50px;
  }
  .hamburger {
    color: v.$theme-main;
    font-size: 2.5rem;
    cursor: pointer;
  }

  .expanded-menu {
    display: none;
    animation: spring 0.5s alternate;
    transform-origin: right;

    li {
      list-style: none;
      cursor: pointer;
      color: v.$text-main;

      .menu-numbering {
        padding-right: 0.25rem;
        color: v.$theme-main;
      }
      .menu-resume {
        color: v.$theme-main;
        padding: 0.75rem 1rem;
        border: solid v.$theme-main 1px;
        border-radius: 5px;
      }
      .menu-resume:hover {
        background-color: rgb(92, 225, 230, 0.2);
      }
    }
    li:hover {
      color: v.$theme-main;
    }
  }

  .dropDown {
    height: 100vh;
    width: 50%;

    position: fixed;
    right: 0;
    top: 0;

    display: none;
    flex-direction: column;
    justify-content: center;

    background-color: rgb(17, 34, 64, 0.9);
    animation: spring 0.5s alternate;
    transform-origin: right;

    .close-dropDown {
      cursor: pointer;
      color: v.$theme-main;
      font-size: 3rem;
      position: absolute;
      top: 25px;
      right: 25px;
    }
    .expanded-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
    }
  }
}

@keyframes spring {
  0% {
    transform: scaleX(1);
  }
  80% {
    transform: scaleX(1.1);
  }
  100% {
    transform: scaleX(1);
  }
}

@media (min-width: 800px) {
  .Navbar {
    .hamburger {
      display: none;
    }
    .expanded-menu {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2rem;
    }
    .dropDown {
      display: none !important;
    }
  }
}
